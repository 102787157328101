import * as React from 'react';
import { Link } from 'gatsby';
import LayoutConsumer from '../components/layout-context';
import FancyTitle from '../components/fancy-title';
import Seo from '../components/seo';

// markup
function SubscriptionCancelledPage() {
  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ fullWidth: true });
        return (
          <>
            <Seo title="Subscription cancelled" />
            <div className="px-4 lg:px-16 flex flex-col py-20 lg:py-36 lg:pb-40 prose text-background dark:text-white max-w-none break-words lg:justify-center lg:min-h-screen">
              <FancyTitle
                text="Subscription cancelled"
                className="text-left"
                marginBottom="mb-0"
                tag="h1"
              />
              <p className="prose text-background-dark dark:text-white mt-0 lg:text-xl">
                <span role="img" aria-label="Pensive emoji">
                  😔
                </span>
                {' '}
                <br />
                You cancelled your subscription or declined the payment for our app.
                <br />
                If you want to re-install the app or searching
                for one of our other apps, please visit the
                {' '}
                <Link
                  to="https://apps.shopify.com/partners/latori"
                  className="text-latori-green font-monospace font-bold uppercase text-left text-xl pt-6 no-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Shopify App Store.
                </Link>
                <br />
                <br />
                Please contact us at any time for more support.
                <br />
                <br />
                The Latori-Team
              </p>
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default SubscriptionCancelledPage;
